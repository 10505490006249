import React from 'react';
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

class VideoPlayerModal extends React.Component {
    constructor(props) {
        super(props);

        this.video = null;
    }

    onClose() {
        this.video.pause();
        this.props.onClose();
    }

    render() {
        return <Dialog
            fullWidth={true}
            maxWidth='xl'
            open={true}
            keepMounted
            onClose={() => this.onClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            {
                this.props.title &&
                    <DialogTitle id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
            }
            <DialogContent>
                <video
                    ref={(ref) => {
                        this.video = ref;
                    }}
                    autoplay='autoplay'
                    style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        width: '100%',
                    }}
                    controls='controls'
                    playsinline='playsinline'
                >
                    {
                        this.props.videoSrcs.map((src) => {
                            if (src.indexOf('webm') !== -1) {
                                return <source src={src} type='video/webm; codecs="vp8, vorbis"'/>
                            } else if (src.indexOf('ogv') !== -1) {
                                return <source src={src} type='video/ogg; codecs="theora, vorbis"' />
                            } else if (src.indexOf('mp4') !== -1) {
                                return <source src={src} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                            } else {
                                return <source src={src}/>
                            }
                        })
                    }
                </video>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.onClose()} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    }
}

VideoPlayerModal.propTypes = {
    title: PropTypes.string.isRequired,
    videoSrcs: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default VideoPlayerModal;
