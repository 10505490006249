const CONFIG = {
    title: 'Сергей Рогожин',
    markerLoadedMessage: 'Маркер добавлен',
    markerFoundMessage: 'Маркер обнаружен',
    markers: [
        {
            title: '',
            videoSrcs: [
                '/assets/rogozhin/video/rogozhin.mp4',
                '/assets/rogozhin/video/rogozhin.webm',
                '/assets/rogozhin/video/rogozhin.ogv',
            ],
            markerSrc: '/assets/rogozhin/raw/img',
        },
    ],
};

export default CONFIG;