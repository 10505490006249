import IMPERATORS_CONFIG from '../assets/imperators/config';
import ROGOZHIN_CONFIG from '../assets/rogozhin/config';
import USSR_CONFIG from '../assets/ussr/config';
import BULANOVA_CONFIG from '../assets/bulanova/config';

const configs = new Map();
configs.set('soviet', USSR_CONFIG);
configs.set('rogozhin', ROGOZHIN_CONFIG);
configs.set('bulanova', BULANOVA_CONFIG);
configs.set('', IMPERATORS_CONFIG);

export default configs;
