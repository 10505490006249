import React from 'react';
import logo from '../assets/images/gold.png';
import '../assets/styles/app.css';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import VideoPlayerModal from "./video-player-modal";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ARnft from '../assets/ARnft';
import 'md-gum-polyfill';

import configs from './configs';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCameraErrorModal: false,
            errorMessage: null,
            isInitialized: false,
            viewMarker: null,

            globalError: null,
            showLogsModal: false,
        };

        this.console = [];
        this.config = null;

        /*this.consoleHandler = this.consoleHandler.bind(this);
        console.log = this.consoleHandler;
        console.warn = this.consoleHandler;
        console.error = this.consoleHandler;*/
    }

    getSubdomain() {
        const parts = window.location.hostname.split('.').reverse();

        if (!parts) {
            console.log('wtf');
        }

        if (parts.length <= 2 || parts[2] === 'www') {
            return '';
        }

        return parts[2];
    }

    consoleHandler() {
        let args = Array.prototype.slice.call(arguments);
        for (let i = 0; i < args.length; i++) {
            this.console.push(args[i]);
        }
    }

    foundMarker(url) {
        let found = null;
        this.config.markers.map((marker) => {
            if (marker.markerSrc === url) {
                found = marker;
            }
        });

        return found;
    }

    initAR() {
        const markers = [];
        this.config.markers.map((marker) => {
            markers.push(marker.markerSrc);
        });

        console.log(ARnft);
        ARnft.ARnft.init(640, 480, markers, '/assets/config.json', true)
            .then((nft) => {
                console.log(nft);
            }).catch((error) => {
                console.log(error);
            })
        ;

        document.addEventListener('camera-init', (e) => {
            this.props.enqueueSnackbar('Камера запущена', {variant: 'success'});
        });

        document.addEventListener('camera-error', (e) => {
            console.log(e);
            this.setState({showCameraErrorModal: true, errorMessage: e.detail.message});
        });

        document.addEventListener('markerLoaded', (e) => {
            this.props.enqueueSnackbar(this.config.markerLoadedMessage, {variant: 'info'});

            if (this.state.isInitialized === false) {
                this.setState({isInitialized: true});
            }
        });

        document.addEventListener('markerFound', (e) => {
            if (this.state.viewMarker === null) {
                this.props.enqueueSnackbar(this.config.markerFoundMessage, {variant: 'success'});
                this.setState({viewMarker: this.foundMarker(e.detail.markerUrl)});
            }
        });
    }

    componentDidMount() {
        if (!window.nativeAlert) {
            window.onerror = (message, url, line, column, error) => {
                this.setState({globalError: {
                    message: message,
                    url: url,
                    line: line,
                    column: column,
                    error: error.toString(),
                }})
            };

            window.nativeAlert = window.alert;
            window.alert = (msg) => {
                if (msg.indexOf('Webcam Error') === 0) {
                    console.log(msg);
                    return;
                }

                window.nativeAlert(msg);
            };
        }

        setTimeout(() => this.initConfig(), 250);
    }

    initConfig() {
        const subdomain = this.getSubdomain();
        if (!configs.has(subdomain)) {
            this.state.showCameraErrorModal = true;
            this.state.errorMessage = 'Неизвестный раздел';
        } else {
            this.config = configs.get(subdomain);
            document.title = this.config.title;
            this.initAR();
        }
    }

    renderCameraErrorModal() {
        if (this.state.showCameraErrorModal === false) {
            return null;
        }

        return (
            <Dialog
                open={true}
                keepMounted
                onClose={() => window.location.reload(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Что-то пошло не так</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Похоже, что у вашего браузера нет прав на использование камеры. Пожалуйста, оформите разрешение в настройках приложений.
                        {
                            this.state.errorMessage !== null &&
                                <p>{this.state.errorMessage}</p>
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.location.reload(false)} color="primary">
                        Попробовать снова
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderGlobalErrorModal() {
        if (this.state.globalError === null) {
            return null;
        }

        return (
            <Dialog
                open={true}
                keepMounted
                onClose={() => this.setState({globlError: null})}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Что-то пошло не так</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.globalError.message}
                        <br/>
                        {this.state.globalError.url}
                        <br/>
                        {this.state.globalError.line}
                        <br/>
                        {this.state.globalError.column}
                        <br/>
                        {this.state.globalError.error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({globalError: null})} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderLogsModal() {
        return this.state.showLogsModal && (
            <Dialog
                open={true}
                keepMounted
                onClose={() => this.setState({globlError: null})}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {
                            this.console.map((item, key) => {
                                if (typeof item === 'object') {
                                    return <p>{JSON.stringify(item)}</p>
                                }

                                return <p>{item.toString()}</p>
                            })
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({showLogsModal: false})} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderVideoPlayerModal() {
        if (this.state.viewMarker === null) {
            return null;
        }

        return <VideoPlayerModal
            title={this.state.viewMarker.title}
            videoSrcs={this.state.viewMarker.videoSrcs}
            onClose={() => this.setState({viewMarker: null})}
        />
    }

    render() {
        return (
            <div className='App'>
                {
                    this.state.isInitialized === false
                    && this.state.showCameraErrorModal === false
                    &&
                    <header className='App-header'>
                        <img src={logo} className='App-logo' alt='logo' />
                    </header>
                }
                {this.renderCameraErrorModal()}
                {this.renderVideoPlayerModal()}
                {this.renderGlobalErrorModal()}
                {this.renderLogsModal()}
            </div>
        );
    }
}

export default withSnackbar(App);
