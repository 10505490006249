const CONFIG = {
    title: 'Конфеты СССР',
    markerLoadedMessage: 'Маркер добавлен',
    markerFoundMessage: 'Маркер обнаружен',
    markers: [
        {
            title: '',
            videoSrcs: [
                '/assets/ussr/video/ussr.mp4',
                '/assets/ussr/video/ussr.ogv',
            ],
            markerSrc: '/assets/ussr/nft/img001',
        },
        {
            title: '',
            videoSrcs: [
                '/assets/ussr/video/ussr.mp4',
                '/assets/ussr/video/ussr.ogv',
            ],
            markerSrc: '/assets/ussr/raw/img002',
        },
        {
            title: '',
            videoSrcs: [
                '/assets/ussr/video/ussr.mp4',
                '/assets/ussr/video/ussr.ogv',
            ],
            markerSrc: '/assets/ussr/raw/img003',
        },
        {
            title: '',
            videoSrcs: [
                '/assets/ussr/video/ussr.mp4',
                '/assets/ussr/video/ussr.ogv',
            ],
            markerSrc: '/assets/ussr/raw/img004',
        },
    ],
};

export default CONFIG;