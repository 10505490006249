const CONFIG = {
    title: 'Шоколад Императора',
    markerLoadedMessage: 'Добавлен император',
    markerFoundMessage: 'Император обнаружен',
    markers: [
        {
            title: 'Петр I',
            videoSrcs: [
                '/assets/imperators/video/mp4/petr_1.mp4',
                '/assets/imperators/video/webm/petr_1.webm',
                '/assets/imperators/video/ogv/petr_1.ogv',
            ],
            markerSrc: '/assets/imperators/nft/petr_1',
        },
        {
            title: 'Петр II',
            videoSrcs: [
                '/assets/imperators/video/mp4/petr_2.mp4',
                '/assets/imperators/video/webm/petr_2.webm',
                '/assets/imperators/video/ogv/petr_2.ogv',
            ],
            markerSrc: '/assets/imperators/nft/petr_2',
        },
        {
            title: 'Петр III',
            videoSrcs: [
                '/assets/imperators/video/mp4/petr_3.mp4',
                '/assets/imperators/video/webm/petr_3.webm',
                '/assets/imperators/video/ogv/petr_3.ogv',
            ],
            markerSrc: '/assets/imperators/nft/petr_3',
        },
        {
            title: 'Александр I',
            videoSrcs: [
                '/assets/imperators/video/mp4/alexander_1.mp4',
                '/assets/imperators/video/webm/alexander_1.webm',
                '/assets/imperators/video/ogv/alexander_1.ogv',
            ],
            markerSrc: '/assets/imperators/nft/alexander_1',
        },
        {
            title: 'Александр II',
            videoSrcs: [
                '/assets/imperators/video/mp4/alexander_2.mp4',
                '/assets/imperators/video/webm/alexander_2.webm',
                '/assets/imperators/video/ogv/alexander_2.ogv',
            ],
            markerSrc: '/assets/imperators/nft/alexander_2',
        },
        {
            title: 'Александр III',
            videoSrcs: [
                '/assets/imperators/video/mp4/alexander_3.mp4',
                '/assets/imperators/video/webm/alexander_3.webm',
                '/assets/imperators/video/ogv/alexander_3.ogv',
            ],
            markerSrc: '/assets/imperators/nft/alexander_3',
        },
        {
            title: 'Анна Иоановна',
            videoSrcs: [
                '/assets/imperators/video/mp4/ann.mp4',
                '/assets/imperators/video/webm/ann.webm',
                '/assets/imperators/video/ogv/ann.ogv',
            ],
            markerSrc: '/assets/imperators/nft/ann',
        },
        {
            title: 'Екатерина I',
            videoSrcs: [
                '/assets/imperators/video/mp4/ekaterina_1.mp4',
                '/assets/imperators/video/webm/ekaterina_1.webm',
                '/assets/imperators/video/ogv/ekaterina_1.ogv',
            ],
            markerSrc: '/assets/imperators/nft/ekaterina_1',
        },
        {
            title: 'Екатерина II',
            videoSrcs: [
                '/assets/imperators/video/mp4/ekaterina_2.mp4',
                '/assets/imperators/video/webm/ekaterina_2.webm',
                '/assets/imperators/video/ogv/ekaterina_2.ogv',
            ],
            markerSrc: '/assets/imperators/nft/ekaterina_2',
        },
        {
            title: 'Елизавета Петровна',
            videoSrcs: [
                '/assets/imperators/video/mp4/elizabeth.mp4',
                '/assets/imperators/video/webm/elizabeth.webm',
                '/assets/imperators/video/ogv/elizabeth.ogv',
            ],
            markerSrc: '/assets/imperators/nft/elizabeth',
        },
        {
            title: 'Иван VI',
            videoSrcs: [
                '/assets/imperators/video/mp4/ivan_6.mp4',
                '/assets/imperators/video/webm/ivan_6.webm',
                '/assets/imperators/video/ogv/ivan_6.ogv',
            ],
            markerSrc: '/assets/imperators/nft/ivan_6',
        },
        {
            title: 'Николай I',
            videoSrcs: [
                '/assets/imperators/video/mp4/nikolay_1.mp4',
                '/assets/imperators/video/webm/nikolay_1.webm',
                '/assets/imperators/video/ogv/nikolay_1.ogv',
            ],
            markerSrc: '/assets/imperators/nft/nikolay_1',
        },
        {
            title: 'Николай II',
            videoSrcs: [
                '/assets/imperators/video/mp4/nikolay_2.mp4',
                '/assets/imperators/video/webm/nikolay_2.webm',
                '/assets/imperators/video/ogv/nikolay_2.ogv',
            ],
            markerSrc: '/assets/imperators/nft/nikolay_2',
        },
        {
            title: 'Павел I',
            videoSrcs: [
                '/assets/imperators/video/mp4/pavel_1.mp4',
                '/assets/imperators/video/webm/pavel_1.webm',
                '/assets/imperators/video/ogv/pavel_1.ogv',
            ],
            markerSrc: '/assets/imperators/nft/pavel_1',
        },
        {
            title: '',
            videoSrcs: [
                '/assets/imperators/video/mp4/center.mp4',
                '/assets/imperators/video/webm/center.webm',
                '/assets/imperators/video/ogv/center.ogv',
            ],
            markerSrc: '/assets/imperators/nft/center',
        },
    ],
};

export default CONFIG;